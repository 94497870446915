.header {
    position: absolute;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    background-color: whitesmoke;

    @include  min($tablet-landscape-and-up) {
        height: 80px;
        background-color: transparent;
    }

    .container {
        margin: auto;
    }

    &__container {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        z-index: 3;
        display: flex;
        width: 100%;
        justify-content: space-between;
        height: 60px;
        transition: all 300ms linear;
    }

    &__phone {
        display: block;
        margin-bottom: 0;
        color: #ccc;
        font-weight: 600;
    }
}

.fixed {
    position: fixed;
    z-index: 999;
    background-color: rgba(white, 0.95);
    color: #333;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.08);
    transition: all 300ms linear;

    ul {
        li {
            color: #333;

            a {
                color: #333;
            }
        }
    }

    .header__phone {
        color: #333;
    }
}
