.modal {
    background-color: white;
    padding: 25px 30px 15px;
    width: 90vw;
    height: 80vh;
    overflow-y: auto;
    color: darkgray;

    @include min($tablet-landscape-and-up) {
        width: 50vw;
        height: 75vh;
    }

    &__title {
        font-size: 16px;
        letter-spacing: -0.5px;
        text-align: left;
        color: $navy;
        margin-bottom: 8px;

        @include min($tablet-landscape-and-up) {
            width: 80%;
            font-size: 24px;
        }
    }

    &__subtitle {
        font-size: 18px;

        @include min($tablet-landscape-and-up) {
            font-size: 20px;
        }
    }

    &__thank-message {
        display: none;
        color: $green;
    }

    &__error-message {
        display: none;
        color: $red;
    }
}

