.bg-lightgray {
    background-color: #eaeff5 !important;
}

.bg-lightgray-2 {
    background-color: #e3e8ee !important;
}

.icon {
    &--51x38 {
        width: 51px;
        height: 38px;
    }
}
