*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    scroll-behavior: smooth;
}

body {
    font-size: $basic-font-size;
    font-family: $basic-font-family;
    font-weight: 300;
    line-height: 1.5;
    color: $black;
}

img {
    vertical-align: top;
    max-width: 100%;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: $light;
    transition: all $transition-duration $transition-function;

    &:hover {
        color: $red;
        transition: all $transition-duration $transition-function;
    }
}

svg {
    width: 100%;
    height: 100%;
}

.section {
    padding: 30px 0;

    @include  min($tablet-portrait-and-up) {
        padding: 45px 0;
    }

    @include  min($desktop-s-and-up) {
        padding: 60px 0;
    }
}
