.progress {
    &-container {
        width: 100%;
        height: 4px;
        background: #ccc;
        top: 0;
        left: 0;
        position: fixed;
    }

    &-bar {
        height: 4px;
        background: #535d69;
        width: 0%;
    }
}
