.hero {
    background-color: #eee;
    height: 100vh;
    background-image: url("../../../images/hero/warehouse.jpg");
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ccc;
    font-size: 18px;

    &::before {
        display: block;
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(20,30,40,0.75);
        pointer-events: none;
    }

    &__container {
        position: relative;
        z-index: 2;

        p {
            font-size: 18px;
            font-weight: 500;
        }
    }
}
