.btn {
    @include box(180px, 40px);
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    border-radius: 4px;
    cursor: pointer;
    transition: all 250ms ease;

    @include  min($desktop-s-and-up) {
        @include box(220px, 45px);
    }

    &--grey {
        color: $white !important;
        background-color: $grey;

        &:hover {
            background-color: rgba($grey, 0.8);
        }
    }

    &--blue {
        color: $white !important;
        background-color: $navy;

        &:hover {
            background-color: $red;
        }
    }

    &--red {
        color: $white !important;
        background-color: $red;

        &:hover {
            background-color: $navy;
        }
    }
}
