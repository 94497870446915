@keyframes smoothShowBlock{
    0%{
        opacity: 0;
        left: 25px;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}
