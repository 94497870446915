.flip-box {
    transform-style: preserve-3d;
    perspective: 1000px;
    max-width: 320px;
    height: 400px;
    border-radius: 4px;
}

.flip-box-front {
    border-radius: 4px;
    text-align: center;
    background-size: cover;
    background-position: center;
    min-height: 300px;
    height: 100%;
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);

    &:after {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        display: block;
        opacity: 0.6;
        background-color: inherit;
        backface-visibility: hidden;
    }

    & .inner {
        transform: translateZ(60px) scale(.94);
        position: absolute;
        left: 0;
        width: 100%;
        outline: 1px solid transparent;
        perspective: inherit;
        z-index: 2;
        height: 100%;
    }
}

.flip-box-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 300px;
    height: 100%;
    text-align: center;
    transform: rotateY(180deg);
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);

    &:after {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: ' ';
        display: block;
        opacity: 0.6;
        background-color: inherit;
        backface-visibility: hidden;
    }
}

.flip-box-back .inner {
    color: white;
    transform: translateY(-50%) translateZ(60px) scale(.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 10px;
    outline: 1px solid transparent;
    perspective: inherit;
    z-index: 2;
}

.flip-box:hover .flip-box-front {
    transform: rotateY(-180deg);
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
}

.flip-box:hover .flip-box-back {
    transform: rotateY(0deg);
    transform-style: preserve-3d;
    transition: transform 0.7s cubic-bezier(.4,.2,.2,1);
}
