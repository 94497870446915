.main-nav {
    position: fixed;
    top: 60px;
    left: 0;
    height: 0;
    overflow-y: auto;
    background: white;
    z-index: 10;
    width: 100vw;
    padding: 0 10px;
    flex-direction: column;
    justify-content: center;
    display: flex;
    transition: all 400ms ease;

    @include min($tablet-landscape-and-up) {
        position: relative;
        z-index: 10;
        width: 100%;
        top: auto;
        left: auto;
        height: auto;
        overflow-y: auto;
        background: inherit;
        padding: 0;
        flex-direction: row;
    }

    &.isActive {
        height: calc(100vh - 60px);
        z-index: 100;
        transition: all 400ms ease;
    }

    &__list {
        @include reset-list();
        display: flex;
        flex-direction: column;

        @include min($tablet-landscape-and-up) {
            flex-direction: row;
        }
    }

    &__item {
        margin: 20px 5px;
        text-align: center;

        @include min($tablet-landscape-and-up) {
            margin: 0 5px;
        }
    }

    &__link {
        text-decoration: none;
        color: #ccc;
        padding: 10px 5px;
        font-weight: 600;
        transition: all 200ms ease;

        @include min($tablet-landscape-and-up) {
            padding: 0 5px;
        }

        &:hover {
            color: $red;
            transition: all 200ms ease;
        }
    }
}

