/* Margins */
h1, h2, h3, h4, h5, h6, p {
    margin: 0 0 10px;

    @include  min($tablet-portrait-and-up) {
        margin: 0 0 20px;
    }
}

/* Sizes */
h1, .h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 30px;

    @include min($tablet-portrait-and-up) {
        font-size: 36px;
    }

    @include min($desktop-s-and-up) {
        font-size: 42px;
        margin: 0 0 40px;
    }
}

h2, .h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 0 20px;

    @include min($tablet-portrait-and-up) {
        font-size: 32px;
        margin: 0 0 30px;
    }

    @include min($desktop-s-and-up) {
        font-size: 36px;
        margin: 0 0 40px;
    }
}

h3, .h3 {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.2;

    @include  min($tablet-portrait-and-up) {
        font-size: 24px;
    }

    @include  min($desktop-s-and-up) {
        font-size: 28px;
    }
}

h4, .h4 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.2;

    @include  min($tablet-portrait-and-up) {
        font-size: 20px;
    }

    @include  min($desktop-s-and-up) {
        font-size: 24px;
    }
}

p, .copy {
    font-size: 14px;
    line-height: 1.4;
    letter-spacing: 0.4px;

    @include  min($desktop-s-and-up) {
        font-size: 16px;
    }
}

.text-large {
    font-size: 14px;
    line-height: 1.3;
    letter-spacing: 0.4px;

    @include  min($desktop-s-and-up) {
        font-size: 18px;
        line-height: 1.4;
    }
}


/* Weight */
.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.bold {
    font-weight: 500;
}

/* Align */
.copy-centered {
    text-align: center;
}

/* Colors */
.text-white {
    color: $light;
}

.text-navy {
    color: $navy;
}

.text-red {
    color: $red;
}
