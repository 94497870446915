.card {
    color: $black;
    background: #fff;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
        box-shadow: 0 24px 38px 3px rgba(0,0,0,.07), 0 9px 46px 8px rgba(0,0,0,.06), 0 11px 15px 0 rgba(0,0,0,.1);
    }

    &__img {
        position: relative;
        padding-bottom: 55%;
        overflow: hidden;
        transition: .25s ease-out;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: transparent;
            transition: .125s ease-out;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    &__inner {
        padding: 20px 30px;
        background: #fff;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__title {
        color: $navy;
    }
}
