.tab {
    display: flex !important;

    &__list {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        padding-right: 20px;
    }

    &__item {
        list-style: none;
        border-bottom: 1px dotted lightgrey;

        &:first-child {
            border-top: 1px dotted lightgrey;
        }
    }

    &__link {
        text-decoration: none;
        display: block;
        position: relative;
        padding: 20px;
        color: $black;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;

        &.isActive {
            background-color: white;
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.11);
            border-radius: 4px;
        }

        &:hover {
            background-color: #f5f5f5;
        }
    }

    &__container {
        width: 100%;
        height: 100%;
        padding-top: 20px;
        padding-left: 20px;
    }

    &__content {
        display: none;
        height: 100%;
        text-align: left;
        position: relative;

        &--mobile {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            p {
                margin-top: 15px;
            }
        }

        p {
            font-size: 18px;
            line-height: 2;
            width: 70%;
        }

        picture {
            width: 25%;
            margin-right: 5%;

            img {
                width: 100%;
            }
        }

        &.isActive {
            display: flex !important;
            align-items: center;
            opacity: 0;
            transition: 500ms;
            animation: smoothShowBlock 500ms 1;
            animation-fill-mode: forwards;
            animation-delay: 50ms;
        }
    }
}
