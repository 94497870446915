.logo {
    width: 60px;
    height: 40px;
    display: block;

    svg {
        width: 100%;
        height: 100%;
        fill: gray;
    }
}
