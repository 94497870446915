input[type='text'],
input[type='email'],
input[type='phone'],
textarea {
    background-color: white;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid lightgrey;
    width: 100%;
    outline: none;
    height: 40px;
    padding-left: 20px;
    margin-bottom: 2rem;
    color: black;
    position: relative;
    z-index: 2;
    transition: all 200ms ease;

    &:-webkit-autofill {
        background-color: white !important;
    }
}

textarea {
    padding: 20px;
    height: 130px;
    border-radius: 5px;
    border: 1px solid lightgrey;
    resize: vertical;
    transition: all 200ms ease;

    &::placeholder {
        color: darkgray;
        font-weight: 300;
    }
}

input[type=text]:focus,
input[type=email]:focus {
    border-bottom: 1px solid grey;
    transition: all 200ms ease;
}

textarea:focus {
    border: 1px solid grey;
    transition: all 200ms ease;
}
