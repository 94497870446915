.footer {
    background-color: #6e7174;
    padding: 16px 0;
    font-weight: 400;

    @include  min($tablet-portrait-and-up) {
        padding: 28px 0;
    }

    @include  min($desktop-s-and-up) {
        padding: 60px 0;
    }

    &__link {
        font-size: 20px;
        line-height: 1.5;
        text-decoration: underline;
        font-weight: 400;
    }
}
