.numbers {
    min-height: calc(60vh);
    position: relative;
    background: url("../../../images/numbers/snake_light.png") center 50% repeat-x;

    &::after {
        @include  min($tablet-landscape-and-up) {
            display: block;
            content: '';
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            bottom: 0;
            width: 45vw;
            height: 100%;
            background-color: rgba(20, 30, 40, 0.2);
            pointer-events: none;
        }
    }

    &:before {
        @include  min($tablet-landscape-and-up) {
            display: block;
            content: '';
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            bottom: 0;
            width: 45vw;
            height: 100%;
            background-image: url("../../../images/numbers/loader.jpeg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }

    p {
        margin-bottom: 10px;
        border-bottom: 1px solid #d6d8dc;
    }
}
